export default [
    'https://ktut.github.io/portfolio/assets/print-design/IMG_0063.jpg',
    'https://ktut.github.io/portfolio/assets/print-design/IMG_0008.jpg',
    'https://ktut.github.io/portfolio/assets/print-design/IMG_0042.jpg',
    'https://ktut.github.io/portfolio/assets/print-design/IMG_0013.jpg',
    'https://ktut.github.io/portfolio/assets/print-design/IMG_0018.jpg',
    'https://ktut.github.io/portfolio/assets/print-design/IMG_0015.jpg',
    'https://ktut.github.io/portfolio/assets/print-design/IMG_0020.jpg',
    'https://ktut.github.io/portfolio/assets/print-design/IMG_0021.jpg',
    'https://ktut.github.io/portfolio/assets/print-design/IMG_0022.jpg',
    'https://ktut.github.io/portfolio/assets/print-design/IMG_0039.jpg',
    'https://ktut.github.io/portfolio/assets/print-design/IMG_0046.jpg',
    'https://ktut.github.io/portfolio/assets/print-design/IMG_0048.jpg',
    'https://ktut.github.io/portfolio/assets/print-design/IMG_0064.jpg',
    'https://ktut.github.io/portfolio/assets/print-design/IMG_0065.jpg',
    'https://ktut.github.io/portfolio/assets/print-design/IMG_0067.jpg',
    'https://ktut.github.io/portfolio/assets/print-design/IMG_0069.jpg',
    'https://ktut.github.io/portfolio/assets/print-design/IMG_0071.jpg',
    'https://ktut.github.io/portfolio/assets/print-design/IMG_0072.jpg',
    'https://ktut.github.io/portfolio/assets/print-design/IMG_0077.jpg',
];